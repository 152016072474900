import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "../store/index";
// import ComingSoon from "../components/ComingSoon.vue";
import Landing from "../views/Landing.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    // name: "Landing",
    component: Landing,
    meta: {
      title: "Shelta - Property Financing Management Company In Nigeria",
      description:
        "Shelta is a Nigerian company that finances and manages properties for landlords while also paying rent for tenants.",
      metaTags: [
        {
          name: "description",
          content: "Home page of Shelta"
        },
        {
          property: "og:description",
          content: "Home Page of Shelta"
        }
      ]
    }
  },
  {
    path: "/login",
    name: "Login",

    component: () => import("../views/Login.vue")
  },
  {
    path: "/signup",
    name: "Signup",

    component: () => import("../views/Signup.vue")
  },
  {
    path: "/invite/:code",
    name: "InviteSignup",

    component: () => import("../views/InviteSignup.vue")
  },
  {
    path: "/shelta",
    name: "Landing",
    component: () => import("../views/Landing.vue"),
    meta: {
      title: "Shelta - Property Financing Management Company In Nigeria",
      description:
        "Shelta is a Nigerian company that finances and manages properties for landlords while also paying rent for tenants.",
      metaTags: [
        {
          name: "description",
          content: "Home page of Shelta"
        },
        {
          property: "og:description",
          content: "Home Page of Shelta"
        }
      ]
    }
  },
  {
    path: "/freelegalservices",
    name: "LitigationHome",
    component: () => import("../components/LitigationHome.vue"),
    meta: {
      title: "Free Legal Services for Property Owners | Guaranteed Rent & Litigation Cover",
      description:
        "Shelta is a Nigerian company that finances and manages properties for landlords while also paying rent for tenants.",
      metaTags: [
        {
          name: "description",
          content: "Unlock free legal services and expert support for Nigerian landlords! Join Shelta's campaign to protect your property and resolve disputes—sign up now!"
        },
        // {
        //   property: "og:description",
        //   content: "Home Page of Shelta"
        // }
      ]
    }
  },
  // {
  //   path: "/home",
  //   name: "Landing2",

  //   component: () => import("../views/Landing2.vue")
  // },
  {
    path: "/safeinvite/:token",
    name: "SafeInvite",
    component: () => import("../components/SafeInvite.vue")
  },
  {
    path: "/aboutus",
    name: "AboutUs",

    component: () => import("../components/AboutUs.vue"),
    meta: {
      title: "About Us",
      description:
        "Shelta is a PropTech startup that provides landlords with guaranteed rent and tenants with flexible payment options.",
      metaTags: [
        {
          name: "description",
          content: "About Us page of Shelta"
        },
        {
          property: "og:description",
          content: "About Us Page of Shelta"
        }
      ]
    }
  },
  {
    path: "/propertylist",
    name: "PropertyListNew",

    component: () => import("../components/PropertyListNew.vue")
  },
  {
    path: "/propertylist2",
    name: "PropertyList",

    component: () => import("../components/PropertyListHome.vue")
  },
  {
    path: "/property/:id",
    name: "PropertyDetails",

    component: () => import("../components/PropertyDetails.vue")
  },
  {
    path: "/property-details",
    name: "PropertyDetails2",

    component: () => import("../components/PropertyDetails2.vue")
  },
  {
    path: "/details",
    name: "Details",

    component: () => import("../components/Details.vue")
  },
  {
    path: "/landlord",
    name: "LandlordPage",

    component: () => import("../components/LandlordPage.vue"),
    meta: {
      title: "Guaranteed Rent For Landlord",
      description:
        "With Shelta, your rental payment for your property will arrive on time every year, with no stress or delay.",
      metaTags: [
        {
          name: "description",
          content: "Landlord page of Shelta"
        },
        {
          property: "og:description",
          content: "Landlord Page of Shelta"
        }
      ]
    }
  },
  {
    path: "/tour",
    name: "Tour",

    component: () => import("../components/Tour.vue")
  },
  {
    path: "/tenant",
    name: "TenantPage",

    component: () => import("../components/TenantPage.vue"),
    meta: {
      title: "Rent Financing For Tenants",
      description:
        "Be part of 500+ tenants who enjoy flexible and convenient rent payments by joining Shelta today.",
      metaTags: [
        {
          name: "description",
          content: "Tenant page of Shelta"
        },
        {
          property: "og:description",
          content: "Tenant Page of Shelta"
        }
      ]
    }
  },
  {
    path: "/organization",
    name: "Organization",

    component: () => import("../components/Organization.vue"),
    meta: {
      title: "Rent Financing For Your Staff",
      description:
        "Partner with us to provide your workforce with a hassle-free and easy way to pay their rent.",
      metaTags: [
        {
          name: "description",
          content: "Organization page of Shelta"
        },
        {
          property: "og:description",
          content: "Organization Page of Shelta"
        }
      ]
    }
  },
  {
    path: "/paginator",
    name: "Pagination",

    component: () => import("../components/Pagination.vue")
  },
  {
    path: "/addproperty",
    name: "AddProperty",

    component: () => import("../components/AddProperty.vue"),
    beforeEnter: (to, from, next) => {
      if (window.localStorage.getItem("shelta")) {
        next();
      } else {
        store.commit("setApiFailed", "Select Landlord");
        next({ name: "Login", query: { redirect: to.fullPath } });
      }
    }
  },
  {
    path: "/editproperty",
    name: "EditPropertyLandlord",

    component: () => import("../components/EditPropertyLandlord.vue"),
    beforeEnter: (to, from, next) => {
      if (window.localStorage.getItem("shelta")) {
        next();
      } else {
        store.commit("setApiFailed", "Select Landlord");
        next({ name: "Login", query: { redirect: to.fullPath } });
      }
    }
  },
  {
    path: "/editpropertytenant",
    name: "TenantEditProperty",

    component: () => import("../components/TenantEditProperty.vue"),
    beforeEnter: (to, from, next) => {
      if (window.localStorage.getItem("shelta")) {
        next();
      } else {
        store.commit("setApiFailed", "Select Tenant");
        next({ name: "Login", query: { redirect: to.fullPath } });
      }
    }
  },
  {
    path: "/tenantaddproperty",
    name: "TenantAddProperty",

    component: () => import("../components/TenantAddProperty.vue"),
    beforeEnter: (to, from, next) => {
      if (window.localStorage.getItem("shelta")) {
        next();
      } else {
        store.commit("setApiFailed", "Please Login");
        next({ name: "Login", query: { redirect: to.fullPath } });
      }
    }
  },
  {
    path: "/addpropertyforsale",
    name: "AddPropertyForSale",
    component: () => import("../components/AddPropertyForSale.vue"),
    beforeEnter: (to, from, next) => {
      if (window.localStorage.getItem("shelta")) {
        next();
      } else {
        store.commit("setApiFailed", "Select Landlord");
        next({ name: "Login", query: { redirect: to.fullPath } });
      }
    }
  },
  {
    path: "/contactus",
    name: "ContactUs",

    component: () => import("../components/ContactUs.vue"),
    meta: {
      title: "Contact Us",
      description:
        "Got a question? Our sales team will be more than happy to talk to you. Contact us today.",
      metaTags: [
        {
          name: "description",
          content: "Contact Us page of Shelta"
        },
        {
          property: "og:description",
          content: "Contact Us Page of Shelta"
        }
      ]
    }
  },
  {
    path: "/faq",
    name: "Faq",

    component: () => import("../components/Faq.vue")
  },
  {
    path: "/privacy&policy",
    name: "PrivacyPolicy",

    component: () => import("../components/PrivacyPolicy.vue")
  },
  {
    path: "/rentpayer",
    name: "RentPayerHome",

    component: () => import("../components/RentPayerHome.vue")
  },
  {
    path: "/payertenant",
    name: "RentPayerTenant",

    component: () => import("../components/RentPayerTenant.vue")
  },
  {
    path: "/payerlandlord",
    name: "RentPayerLandlord",

    component: () => import("../components/RentPayerLandlord.vue")
  },
  {
    path: "/blogs",
    name: "Blog",

    component: () => import("../components/Blog.vue")
  },
  {
    path: "/blog/:id",
    name: "BlogRead",

    component: () => import("../components/BlogRead.vue")
  },
  {
    path: "/setup",
    name: "ProfileSignup",

    component: () => import("../views/ProfileSignup.vue")
  },
  {
    path: "/sure",
    name: "Sure",

    component: () => import("../components/Sure2023.vue")
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",

    component: () => import("../views/ForgotPassword.vue")
  },
  {
    path: "/recoverpassword/:token",
    name: "RecoverPassword",

    component: () => import("../views/RecoverPassword.vue")
  },
  {
    path: "/verification",
    name: "Verification",

    component: () => import("../views/Verification.vue")
  },
  {
    path: "/dashboard",
    name: "Navigation",
    beforeEnter: (to, from, next) => {
      if (window.localStorage.getItem("shelta")) {
        next();
      } else {
        store.commit("setApiFailed", "Select Landlord");
        next({ name: "Login", query: { redirect: to.fullPath } });
      }
    },

    component: () => import("../views/Navigation.vue"),
    children: [
      {
        path: "landlord",
        component: () => import("../components/Dashboard.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta-loger") === "Landlord") {
            next();
          } else {
            store.commit("setApiFailed", "Select Landlord");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "addpropertytenant",
        name: "AddPropertyTenant",
        component: () => import("../components/AddPropertyTenant.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta-loger") === "Tenant") {
            next();
          } else {
            store.commit("setApiFailed", "Select Tenant");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "applyrentfinancing/:pin",
        name: "ApplyRentFinancing",
        component: () => import("../components/ApplyRentFinancing2.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta-loger") === "Tenant") {
            next();
          } else {
            store.commit("setApiFailed", "Select Tenant");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "joininvestment",
        name: "InvestJoin",
        component: () => import("../components/invest/InvestJoin.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta")) {
            next();
          } else {
            store.commit("setApiFailed", "Login");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "invest",
        name: "invest",
        component: () => import("../views/InvestView.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta")) {
            next();
          } else {
            store.commit("setApiFailed", "Login");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "investment-dashboard",
        name: "InvestmentDashboard",
        component: () => import("../components/invest/InvestmentDasboard.vue"),
        beforeEnter: (to, from, next) => {
          if (
            window.localStorage.getItem("shelta")
          ) {
            next();
          } else {
            store.commit("setApiFailed", "Login");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "investment-transactions",
        name: "InvestmentTransactions",
        component: () =>
          import("../components/invest/InvestmentTransactions.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta")) {
            next();
          } else {
            store.commit("setApiFailed", "Login");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "smart-lock",
        name: "SmartLock",
        component: () => import("../components/invest/SmartLock.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta")) {
            next();
          } else {
            store.commit("setApiFailed", "Login");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      //this should be a dynamic path that will fetch the asset based on the ID
      {
        path: "assetId/:id",
        component: () => import("../views/AssetInfo.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta")) {
            next();
          } else {
            store.commit("setApiFailed", "Login");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "e-wallet",
        name: "Wallet",
        component: () => import("../components/Wallet.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta")) {
            next();
          } else {
            store.commit("setApiFailed", "Login");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "safe",
        name: "Safe",
        component: () => import("../components/Safe.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta")) {
            next();
          } else {
            store.commit("setApiFailed", "Login");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "safetransaction",
        name: "SafeTransactionHistory",
        component: () => import("../components/SafeTransactionHistory.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta")) {
            next();
          } else {
            store.commit("setApiFailed", "Login");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "paymentbreakdown",
        // name: "SafeTransactionHistory",
        component: () => import("../components/SafePaymentBreakdown.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta")) {
            next();
          } else {
            store.commit("setApiFailed", "Login");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "safeprojectdetails/:id",
        name: "SafeProjectDetails",
        component: () => import("../components/SafeProjectDetails.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta")) {
            next();
          } else {
            store.commit("setApiFailed", "Login");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "autodebithistory",
        name: "SafeAutoDebitHistory",
        component: () => import("../components/SafeAutoDebitHistory.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta")) {
            next();
          } else {
            store.commit("setApiFailed", "Login");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "safeactivation",
        name: "SafeActivation",
        component: () => import("../components/SafeActivation.vue"),
        beforeEnter: (to, from, next) => {
          if (
            (window.localStorage.getItem("shelta") &&
              window.localStorage.getItem("safeType") === "yellow") ||
            window.localStorage.getItem("safeType") === "blue"
          ) {
            next();
          } else {
            store.commit("setApiFailed", "Chaose a safe type");
            next({ name: "Safe", query: { redirect: "/dashboard/safe" } });
          }
        }
      },
      {
        path: "autodebit",
        name: "SafeSchedule",
        component: () => import("../components/SafeSchedule.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta")) {
            next();
          } else {
            store.commit("setApiFailed", "Login");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "safeprojects",
        name: "SafeProjects",
        component: () => import("../components/SafeProjects.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta")) {
            next();
          } else {
            store.commit("setApiFailed", "Login");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "newproject",
        name: "SafeNewProject",
        component: () => import("../components/SafeNewProject.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta")) {
            next();
          } else {
            store.commit("setApiFailed", "Login");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "editproject",
        name: "SafeEditProject",
        component: () => import("../components/SafeEditProject.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta")) {
            next();
          } else {
            store.commit("setApiFailed", "Login");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "paymyrent",
        name: "PayMyRentTenant",
        component: () => import("../components/PayMyRentTenant.vue"),
        beforeEnter: (to, from, next) => {
          if (
            window.localStorage.getItem("shelta") &&
            window.localStorage.getItem("shelta-loger") === "Tenant"
          ) {
            next();
          } else {
            store.commit("setApiFailed", "Login");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "addlandlord",
        name: "TenantAddLandlordPm",
        component: () => import("../components/TenantAddLandlordPm.vue"),
        beforeEnter: (to, from, next) => {
          if (
            window.localStorage.getItem("shelta") &&
            window.localStorage.getItem("shelta-loger") === "Tenant"
          ) {
            next();
          } else {
            store.commit("setApiFailed", "Login");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "addtenant",
        name: "LandlordAddTenant",
        component: () => import("../components/LandlordAddTenant.vue"),
        beforeEnter: (to, from, next) => {
          if (
            window.localStorage.getItem("shelta") &&
            window.localStorage.getItem("shelta-loger") === "Landlord"
          ) {
            next();
          } else {
            store.commit("setApiFailed", "Login");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "addpm",
        name: "LandlordAddPM",
        component: () => import("../components/LandlordAddPM.vue"),
        beforeEnter: (to, from, next) => {
          if (
            window.localStorage.getItem("shelta") &&
            window.localStorage.getItem("shelta-loger") === "Landlord"
          ) {
            next();
          } else {
            store.commit("setApiFailed", "Login");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "paymentschedule",
        name: "PaymentSchedule",
        component: () => import("../components/PaymentSchedule.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta-loger") === "Tenant") {
            next();
          } else {
            store.commit("setApiFailed", "Login");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "rented",
        name: "Rented",
        component: () => import("../components/Rented.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta-loger") === "Tenant") {
            next();
          } else {
            store.commit("setApiFailed", "Select Tenant");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "renteddetails",
        name: "RentedPropertyDetails",
        component: () => import("../components/RentedPropertyDetails.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta-loger") === "Tenant") {
            next();
          } else {
            store.commit("setApiFailed", "Select Tenant");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "switch/:id",
        name: "SwitchPlan",
        component: () => import("../components/SwitchPlan.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta")) {
            next();
          } else {
            store.commit("setApiFailed", "Login");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "switchagreement/:id",
        name: "SwitchTenancyAgreement",
        component: () => import("../components/SwitchTenancyAgreement.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta")) {
            next();
          } else {
            store.commit("setApiFailed", "Login");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "rentfinancingagreement",
        name: "RentFinancingAgreement",
        component: () => import("../components/RentFinancingAgreement.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta-loger") === "Tenant") {
            next();
          } else {
            store.commit("setApiFailed", "Login");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "rent-summary",
        name: "SwitchPropertySummary",
        component: () => import("../components/SwitchPropertySummary.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta")) {
            next();
          } else {
            store.commit("setApiFailed", "Login");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "ovadraft",
        name: "VendorAgent",
        component: () => import("../components/overdraft/VendorAgent.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta")) {
            next();
          } else {
            store.commit("setApiFailed", "Login");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "join-ovadraft",
        name: "JoinOvadraft",
        component: () => import("../components/overdraft/JoinOvadraft.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta")) {
            next();
          } else {
            store.commit("setApiFailed", "Login");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "cardrequests",
        name: "CardRequestUsers",
        component: () => import("../components/overdraft/CardRequestUsers.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta")) {
            next();
          } else {
            store.commit("setApiFailed", "Login");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "ovadraft-requests",
        name: "PendingLinkedRequests",
        component: () =>
          import("../components/overdraft/PendingLinkedRequests.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta")) {
            next();
          } else {
            store.commit("setApiFailed", "Login");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "ovadraft-activities",
        name: "RecentActivities",
        component: () => import("../components/overdraft/RecentActivities.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta")) {
            next();
          } else {
            store.commit("setApiFailed", "Login");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "subscription",
        name: "subscription",
        component: () => import("../components/Subscription.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta")) {
            next();
          } else {
            store.commit("setApiFailed", "Please login");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "viewbio",
        name: "viewbio",
        component: () => import("../components/ViewBioProfile.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta")) {
            next();
          } else {
            store.commit("setApiFailed", "Please login");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "facilitycheck",
        component: () => import("../components/FacilityCheck.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta")) {
            next();
          } else {
            store.commit("setApiFailed", "Please login");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "facilityissues",
        component: () => import("../components/FacilityIssue.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta")) {
            next();
          } else {
            store.commit("setApiFailed", "Please login");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "tenant",
        name: "TenantDashboard",
        component: () => import("../components/TenantDashboard.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta-loger") === "Tenant") {
            next();
          } else {
            store.commit("setApiFailed", "Select Tenant");
            next({ name: "Login", query: { redirect: to.fullPath } });
          }
        }
      },
      {
        path: "rent/:id",
        component: () => import("../components/RentApplication.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta")) {
            next();
          } else {
            store.commit("setApiFailed", "Please Login");
            // next({name: "Login", query: {redirect: to.fullPath}})
          }
        }
      },

      {
        path: "propertyagreement",
        name: "PropertyManagementAgreement",
        component: () =>
          import("../components/PropertyManagementAgreement.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta-loger") === "Landlord") {
            next();
          } else {
            store.commit("setApiFailed", "Select Landlord");
            // next({name: "Login", query: {redirect: to.fullPath}})
          }
        }
      },
      {
        path: "landlordproperties",
        name: "LandlordProperties",
        component: () => import("../components/LandlordProperties.vue"),
        beforeEnter: (to, from, next) => {
          if (window.localStorage.getItem("shelta-loger") === "Landlord") {
            next();
          } else {
            store.commit("setApiFailed", "Select Landlord");
            // next({name: "Login", query: {redirect: to.fullPath}})
          }
        }
      },
      {
        path: "properties",
        component: () => import("../components/Properties.vue")
      },
      {
        path: "profile",
        component: () => import("../components/Profile.vue")
      },
      {
        path: "facilities",
        component: () => import("../components/Facilities.vue")
      },
      {
        path: "biodata",
        component: () => import("../components/Biodata.vue")
      }
    ]
  },
  {
    path: "/safe",
    name: "SafeHome",
    component: () => import("../views/SafeHome.vue"),
    meta: {
      title: "Fund Your Projects With SAFE",
      description:
        "You can rely on SAFE to fully fund your project through Gap-Financing while you maintain your payment schedule.",
      metaTags: [
        {
          name: "description",
          content: "SAFE Us page of Shelta"
        },
        {
          property: "og:description",
          content: "SAFE Us Page of Shelta"
        }
      ]
    }
  },

  {
    path: "/join",
    name: "join",
    component: () => import("../views/FindProperty.vue")
  },
  {
    path: "/event",
    name: "event page",
    component: () => import("../views/EventPage.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "shelta-active-link"
});

// router.beforeEach((to, from, next) => {
//   // if this isn't an initial page load. // dealing with route defined name and
//   // not component defined name.
//   if (to.name) {
//     store.state.pageLoader = true;
//   }
//   next();
// });
// ...

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  if (to.name) {
    store.state.pageLoader = true;
  }
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    el => {
      if (el.parentNode) el.parentNode.removeChild(el);
    }
  );
  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef: { [x: string]: string }) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag: any) => document.head.appendChild(tag));
  next();
});

// ...
router.afterEach((to, from) => {
  store.state.pageLoader = false;
});
export default router;
